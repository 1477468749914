const gaEventSelector = '[data-ga4-event]';
const gaEventName = 'ga4Event';
const gaParamPrefix = 'ga4Param';
const toSnakeCase = (str) => {
  return `${str}`
    .match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)
    .map(x => x.toLowerCase())
    .join('_');
}

const extractGaEventData = (element) => {
  const eventData = {
    eventName: element.dataset[gaEventName]
  };

  Object.entries(element.dataset).forEach(([key, value]) => {
    const gaParamMatch = key.match(`${gaParamPrefix}(.+)`);

    if (gaParamMatch && gaParamMatch[1]) {
      const formattedKey = toSnakeCase(gaParamMatch[1]);
      eventData[formattedKey] = value;
    }
  });

  return eventData;
}

const addGoogleAnalyticsEventTrackers = (rootEl) => {
  if (!window.ga4) {
    return;
  }

  rootEl.querySelectorAll(gaEventSelector).forEach((el) => {
    el.addEventListener('click', function (event) {
      const target = event.currentTarget;
      if (target) {
        const { eventName, ...params } = extractGaEventData(target);
        window.ga4('event', eventName, params);
      }
    });
  });
}

export { addGoogleAnalyticsEventTrackers };
