import { addGoogleAnalyticsEventTrackers } from '../_analytics/add-google-analytics-event-trackers';

/**
 * Applies on click handler to elements with data attribute `data-ga4-event` and pushes to dataLayer as event.
 * The value from attribute `data-ga4-event` will be used as the event name. Any data attributes prefixed with
 * `data-ga4-param-{param}` will be used with the event. The {param} is extracted and used as event data key and
 * will be converted to snake-case for GA4.
 *
 * With the following HTML:
 *  <button type="button" data-ga4-event="product_cta_click" data-ga4-param-product-name="12oz mug" data-ga4-param-type="button">Create</button>
 * An event with the attributes below will be recorded:
 *  Event name: "product_cta_click"
 *  Parameters: { product_name: "12oz mug", type: "button" }
 */
(function () {
   const onPageReady = (callback) => {
    if (document.readyState === 'complete') {
      callback(document);
    } else {
      document.addEventListener('readystatechange', function onReadyStateChange() {
        onPageReady(callback);
        document.removeEventListener('readystatechange', onReadyStateChange);
      });
    }
  }

  onPageReady(addGoogleAnalyticsEventTrackers);
}).call(this);
