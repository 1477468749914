/* eslint-disable
    camelcase,
    func-names,
    guard-for-in,
    import/no-unresolved,
    max-len,
    no-restricted-syntax,
    no-undef,
    no-use-before-define,
    no-var,
    vars-on-top,
*/
// TODO: This file was created by bulk-decaffeinate.
// Fix any style issues and re-enable lint.
/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */

'order_app.coffee';
'use strict';

import Handlebars from 'handlebars';
// const Handlebars = require('handlebars');

const App = {};
window.App = App;

App.order = {
  no_items: 0,
  cost: '',

  logged_in() {
    return !!this.logged_in_user_name;
  },

  empty() {
    return this.no_items === 0;
  },

  items_text() {
    if (this.no_items === 1) {
      return '1 item';
    } return `${this.no_items} items`;
  },

  // usage
  // App.order.set('cost', data.totals.items_total);
  // App.order.set('cost': 234)
  set(key, value) {
    this[key] = value;
    return update_template();
  },

  // usage
  // App.order.setProperties(<%={ 'order_id' => @order.order_id, 'no_items' => @order.no_items, 'cost' => @order.basic_total.pence_to_price }.to_safe_json %>);
  // App.order.setProperties({'order_id': '12345', 'no_items': 3, 'cost': 234})
  setProperties(properties) {
    for (const key in properties) {
      const value = properties[key]; this[key] = value;
    }
    update_template();
    if (this.logged_in()) {
      $('body').addClass('logged-in');
    } else {
      $('body').removeClass('logged-in');
    }
    return null;
  },
};

// hash of templates with key of their target ids
const templates = {};
// populate hash of templates
(function () {
  for (const template of Array.from($('script[type="text/x-handlebars"]'))) {
    const $template = $(template);
    templates[`#${$template.data('target')}`] = Handlebars.compile($template.html());
  }
  return undefined;
}());

var update_template = function () {
  for (const template_target in templates) {
    const template = templates[template_target];
    $(template_target).html(template(App.order));
  }
  return undefined;
};

$(() => update_template());

window.notEmpty = function () {};
window.warnEmpty = function () {};
