$(() => {
  if (typeof dataLayer !== 'object') return;

  dataLayer.push({ criteoDeviceType: getDeviceType() });

  // for CMS pages
  const products = $('a[data-catalog-item-id]').map(function () {
    const $this = $(this);

    return {
      catalog_item_id: $this.data('catalog-item-id'),
      sku: $this.data('sku'),
    };
  }).get();

  if (products.length) {
    dataLayer.push({
      products,
    });
  }
});

function getDeviceType() {
  if (window.MobileDetect) {
    const md = new MobileDetect(window.navigator.userAgent);
    if (md.phone()) {
      return 'm';
    }

    if (md.tablet()) {
      return 'm';
    }
  }

  return 'd'; // Default to desktop
}
