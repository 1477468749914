/* eslint-disable
    no-undef,
*/
// TODO: This file was created by bulk-decaffeinate.
// Fix any style issues and re-enable lint.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import jstz from 'jstz';


if (!window.BrowserTZone) {
  window.BrowserTZone = {};
}
BrowserTZone.setCookie = () => $.cookie('browser.timezone', jstz.determine().name(), { expires: 365, path: '/' });

$(() => BrowserTZone.setCookie());
