(function () {
  const global = window;

  global.logstashLoginTester = (function () {
    // TODO - put cookie-less user tracking in here ?
    return function logstashLoginTester(state) {
      if (state) {
        // track user's attempt to login
        $.post('/logstash/logintracking', {});
      }
    };
  }());

  return global;
}).call(this);
